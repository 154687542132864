import { type MetaFunction } from '@remix-run/react';
import { Outlet } from '@remix-run/react';

import { makeTitle } from '../../src/utils/common';
import { PageAnalyticsSetup } from '../components/PageAnalyticsSetup';

// This entire file and _client-only pattern is to ensure that the ReactRouter
// routes can ignore SSR for now, until they are ported.

export default function Component() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100%',
        position: 'fixed',
        background: 'black',
      }}
    >
      <PageAnalyticsSetup>
        <Outlet />
      </PageAnalyticsSetup>
    </div>
  );
}

export const meta: MetaFunction = () => {
  const desc =
    'Company Culture Reimagined For Modern Teams, Leverage the power of hosted social games and icebreakers to boost team engagement.';

  return [
    { title: makeTitle('') },
    { 'og:title': 'Luna Park' },
    { 'og:description': desc },
    { description: desc },
  ];
};
